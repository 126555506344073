<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Code -->
          <b-col cols="12">
            <b-form-group id="input-code">
              <label for="codeInput">Code: <span class="text-danger">*</span></label>
              <b-form-input
                id="codeInput"
                name="code"
                :disabled="!isCreated"
                v-model="editedItem.code"
                type="text"
                aria-describedby="input-code"
                data-vv-as="code"
                :class="{ input: true, 'is-danger': errors.has('code') }"
                v-validate="{ required: true, min: 1, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('code')" class="is-danger-text position-relative">{{
                errors.first('code')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- select Coupon -->
          <b-col cols="12">
            <b-form-group id="input-coupon_type" label="CouponType:" label-for="CouponType">
              <b-form-select
                v-model="selectedCouponType"
                :options="allCouponTypeList"
                name="coupon_type"
                data-vv-as="coupon_type"
                :class="{ input: true, 'is-danger': errors.has('coupon_type') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Coupon Type--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Game--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('coupon_type')" class="is-danger-text position-relative">{{
                errors.first('coupon_type')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Note -->
          <b-col cols="12">
            <b-form-group id="input-note">
              <label for="noteInput">Note: <span class="text-danger">*</span></label>
              <b-form-input
                id="noteInput"
                name="note"
                v-model="editedItem.note"
                type="text"
                aria-describedby="input-note"
                data-vv-as="note"
                :class="{ input: true, 'is-danger': errors.has('note') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('note')" class="is-danger-text position-relative">{{
                errors.first('note')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="statusInput">
              <b-form-select
                id="statusInput"
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">--Please select Status--</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Coupon List</h4>
      </b-col>

      <!-- Filter Code -->
      <b-col cols="4">
        <b-form-group id="input-code-filter" label="Code" label-for="code-filter">
          <b-form-input
            id="code-filter"
            v-model="filter_code"
            type="text"
            placeholder="Search code..."
            @input="onFilterCode"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!--Filter Coupon -->
      <b-col cols="2">
        <b-form-group id="input-screen-filter" label="Coupon" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_coupon" :options="allCouponTypeList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>

      <b-col cols="2" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(num_of_used)="item">
        {{ formatPrice(item.item.num_of_used, '') }}
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'Coupon',
  components: {},
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_status: null,
      filter_code: '',
      filter_coupon: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Coupon Type', key: 'coupon_type' },
        { text: 'Num Of Used', key: 'num_of_used' },
        { text: 'Note', key: 'note' },
        { text: 'Code', key: 'code' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allCouponTypeList: [],
      selectedCouponType: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        type: '',
        num_of_used: '',
        note: '',
        code: '',
        status: '',
        coupon_type: ''
      },
      dialog: false,
      editedIndex: -1,
      editorConfig: {
        language: 'de'
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        (!this.editedItem.status && this.errors.has('Status')) ||
        this.errors.has('code') ||
        !this.editedItem.code ||
        this.errors.has('note ') ||
        !this.editedItem.note ||
        !this.selectedCouponType
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Coupont List' }]);
  },
  methods: {
    onFilterCode: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const couponType = ApiService.get(
        'coupon',
        `?code=${this.filter_code}&coupon=${this.filter_coupon || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      const allCouponType = ApiService.get('allcoupontype');
      axios
        .all([couponType, allCouponType])
        .then(
          axios.spread((...response) => {
            const couponTypeRes = response[0];
            const allCouponTypeRes = response[1];
            this.items = couponTypeRes.data.data.roleList.map((ads) => {
              return {
                ...ads
              };
            });
            this.rows = couponTypeRes.data.data.total;
            this.allCouponTypeList = allCouponTypeRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.isCreated = true;
      this.dialog = true;
      this.modalTitle = 'Add Coupon';
    },
    editItem: function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Coupon';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      // this.filter_code = item.email;
      this.filter_coupon = item.coupon_type;
      this.selectedCouponType = item.type;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`coupon/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Coupont deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        type: '',
        num_of_used: '',
        note: '',
        code: '',
        status: '',
        coupon_type: ''
      };
      this.editedIndex = -1;
      this.selectedCouponType = null;
      this.filter_code = null;
      this.filter_status = null;
      this.filter_coupon = '';
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      this.editedItem.coupon_type = this.selectedCouponType;
      this.editedItem.status = this.filter_status;
      let couponData = {
        code: this.editedItem.code,
        note: this.editedItem.note,
        status: this.editedItem.status,
        type: this.selectedCouponType
      };
      if (this.editedIndex > -1) {
        // Update coupon type
        axios({
          method: 'PUT',
          url: `coupon/${this.items[this.editedIndex].id}`,
          data: couponData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              this.allCouponTypeList.some((CouponType) => {
                if (CouponType.value == this.selectedCouponType) {
                  this.editedItem.coupon_type = CouponType.text;
                  this.editedItem.type = CouponType.value;
                  return true;
                }
              });
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Coupon updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast(`errors`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        // Add coupon type
        axios({
          method: 'POST',
          url: 'coupon',
          data: couponData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              this.editedItem = response.data.data;
              this.allCouponTypeList.some((CouponType) => {
                if (CouponType.value == this.selectedCouponType) {
                  this.editedItem.coupon_type = CouponType.text;
                  this.editedItem.type = CouponType.value;
                  return true;
                }
              });
              this.items.unshift({
                ...this.editedItem
              });
              this.close();
              this.$bvToast.toast('Coupon added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
